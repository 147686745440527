import { BookingSnap, byBookingIdUploadSiteQuery, fetchUserProfile, getAccountsQuery, getLabUploaderBookings, getUserBookings, getUserProfileId, JobSnap, Snapshot } from '@ht-lib/accounts-common'
import { Booking, BookingPhotographer, Job, userHasRoles, UserRoles, WithId } from '@ht-lib/accounts-models'
import type firebase from 'firebase/compat/app'
import some from 'lodash/some'
import { Vue } from 'vue-property-decorator'

export interface SimpleBooking {
  id: string
  data: Booking
  ref: firebase.firestore.DocumentReference<Booking>
}

export function toSimpleBooking (bookingEntry: BookingEntry): SimpleBooking {
  const data = bookingEntry.booking.data()
  if (data === undefined) {
    throw Error(`toSimpleBooking - No Booking data! (${ bookingEntry.booking.id })`)
  }

  return {
    id: bookingEntry.booking.id,
    data,
    ref: bookingEntry.booking.ref
  }
}

export class BookingEntry {
  private _loading = true
  readonly jobs: JobSnap[] = []
  readonly jobListener: Snapshot

  get loading (): boolean {
    return this._loading
  }

  constructor (public readonly booking: BookingSnap) {
    this.booking = booking
    this.jobListener = byBookingIdUploadSiteQuery(this.booking.id)
      .onSnapshot(snapshot => {
        snapshot
          .docChanges()
          .forEach(change => {
            if (change.type === 'added') {
              this.jobs.push(change.doc)
            } else if (change.type === 'modified') {
              const index = this.jobs.findIndex(x => x.id === change.doc.id)
              this.jobs.splice(index, 1, change.doc)
            } else if (change.type === 'removed') {
              const index = this.jobs.findIndex(x => x.id === change.doc.id)
              this.jobs.splice(index, 1)
            }
          })

        if (this._loading) {
          this._loading = false
        }
      })
  }
}

export interface BookingsMap {
  [id: string]: BookingEntry
}

export enum BookingStoreKind {
  Account = 'Account',
  Photographer = 'Photographer',
  LabUploader = 'LabUploader'
}

class BookingStore {
  protected _loading = true
  private _size = 0
  readonly bookingMap: BookingsMap = {}
  private bookingListener?: Snapshot

  get loading (): boolean {
    return this._loading || some(this.bookingMap, x => x.loading)
  }

  get size (): number {
    return this._size
  }

  public constructor (private readonly type: BookingStoreKind) {
    this.init().catch(e => { console.error(e) })
  }

  cleanup (): void {
    Object.values(this.bookingMap).forEach(b => b.jobListener())
    if (this.bookingListener !== undefined) {
      this.bookingListener()
    }
  }

  private initializeBooking (change: firebase.firestore.DocumentChange<Booking>): void {
    const { doc, type } = change
    const data = doc.data()
    if (data === undefined) {
      return
    }

    if (type === 'added') {
      const entry = new BookingEntry(doc)
      Vue.set(this.bookingMap, doc.id, entry)
      this._size++
    } else if (type === 'modified') {
      Vue.set(this.bookingMap[doc.id], 'booking', doc)
    } else if (type === 'removed') {
      this.bookingMap[doc.id]?.jobListener()
      Vue.delete(this.bookingMap, doc.id)
      this._size--
    }
  }

  protected async init (): Promise<void> {
    const uid = await getUserProfileId()
    if (uid == null) { return }

    this.bookingListener = this.getQuery(uid)
      .onSnapshot(snap => {
        snap
          .docChanges()
          .forEach(x => this.initializeBooking(x))

        if (this._loading) {
          this._loading = false
        }
      })
  }

  private getQuery (uid: string): firebase.firestore.Query<Booking> {
    switch (this.type) {
      case BookingStoreKind.Account: return getAccountsQuery(uid)
      case BookingStoreKind.Photographer: return getUserBookings(uid)
      case BookingStoreKind.LabUploader: return getLabUploaderBookings(uid)
    }
  }
}

class AccountHolderBookingStore extends BookingStore {
  constructor () {
    super(BookingStoreKind.Account)
  }
}

class PhotographerBookingStore extends BookingStore {
  constructor () {
    super(BookingStoreKind.Photographer)
  }
}

class LabUploaderBookingStore extends BookingStore {
  private _isLabUploader = false
  constructor () {
    super(BookingStoreKind.LabUploader)
  }

  get isLabUploader (): boolean { return this._isLabUploader }

  protected async init (): Promise<void> {
    const labUploaderRoles = [UserRoles.SOFTWARE_GODS, UserRoles.QC_REVIEWER]
    const profile = await fetchUserProfile()
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    this._isLabUploader = profile == null ? false : userHasRoles(profile.data(), labUploaderRoles)

    if (this.isLabUploader) {
      await super.init()
    } else {
      this._loading = false
    }
  }
}

// Export these two until we can sort the mess in Worksheet page.
export const accountsBookingStore = Vue.observable(new AccountHolderBookingStore())
export const yourBookingStore = Vue.observable(new PhotographerBookingStore())
export const labUploaderBookingStore = Vue.observable(new LabUploaderBookingStore())

export const BookingStoreProvider = Vue.extend({
  name: 'BookingStoreProvider',
  render (h) {
    if (this.$scopedSlots.default !== undefined) {
      const photographer = yourBookingStore
      const account = accountsBookingStore
      return h('div', this.$scopedSlots.default({
        account: account,
        photographer: photographer,
        labUploader: labUploaderBookingStore,
        loading: account.loading || photographer.loading || labUploaderBookingStore.loading
      }))
    }

    return h('div', this.$slots.default)
  }
})

class LabUploadDataProvider {
  private files: FileList | null = null
  private booking: WithId<Booking> | null = null
  private photographer: BookingPhotographer | null = null
  private jobType: string | null = null
  private job: WithId<Job> | null = null

  public setFiles (
    files: FileList
  ): void {
    this.files = files
  }

  public setData (
    booking: WithId<Booking>,
    photographer: BookingPhotographer,
    jobType: string,
    job: WithId<Job>
  ): void {
    this.booking = booking
    this.photographer = photographer
    this.jobType = jobType
    this.job = job
  }

  public getFiles (): FileList { return this.valueOrThrow(this.files) }
  public getBooking (): WithId<Booking> { return this.valueOrThrow(this.booking) }
  public getPhotographer (): BookingPhotographer { return this.valueOrThrow(this.photographer) }
  public getJobType (): string { return this.valueOrThrow(this.jobType) }
  public getJob (): WithId<Job> | null { return this.job }

  private valueOrThrow<T> (value: T | null): T {
    if (value == null) {
      throw Error('Expected value to not be null')
    }

    return value
  }
}

export let labUploadDataProvider = new LabUploadDataProvider()

export const clearDataProvider = (): void => {
  labUploadDataProvider = new LabUploadDataProvider()
}
